@font-face {
  font-family: 'Golos-Bold';
  src: url('../public/fonts/golos/GolosText-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Golos-ExtraBold';
  src: url('../public/fonts/golos/GolosText-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Golos-Medium';
  src: url('../public/fonts/golos/GolosText-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Golos-Regular';
  src: url('../public/fonts/golos/GolosText-Regular.ttf') format('truetype');
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Golos-Medium;
  margin: 0 auto;
  max-width: 430px;
  text-align: center;
  width: 100vw;
  height: 100vh;
  background-color: white;
  position: relative;
}

.button-lang {
  border: 1px solid white;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px 12px;
  cursor: pointer;
}

.button-lang_popup {
  border: 1px solid white;
  background-color: #bbf429;
  border-radius: 24px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px 12px;
  cursor: pointer;
}
