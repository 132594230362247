.wrap {
  display: flex;
  flex-direction: column;
  max-width: 397px;
  border-radius: 12px 12px 0 0;
  padding: 18px;
  transition: 1s;
}

.regular {
  align-items: center;
  gap: 40px;
  height: 165px;
  background-color: black;

  .timeWrap {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .title {
      font-size: 16px;
      color: #a7a7a7;
    }

    .time {
      font-size: 24px;
      color: white;
    }
  }
}

.big {
  align-items: flex-start;
  position: absolute;
  height: 290px;
  transition: 0.5s;
  z-index: 100;
  bottom: 5px;
  max-width: 392px;
  width: 91vw;
}

.pending {
  background-color: white;
  gap: 24px;

  .loaderWrap {
    animation:
      spin 2s linear infinite,
      blink 1s infinite;
  }

  span {
    font-size: 24px;
  }
}

.success {
  background-color: #bbf429;
}

.falled {
  background-color: #f4292c;

  span {
    color: white;
  }
}

.overdue {
  background-color: #f4292c;
}

.description {
  font-size: 29px;
  margin-top: 24px;
  margin-bottom: 29px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }
}
