.wrap {
  cursor: pointer;
  display: flex;
  align-items: center;
  max-width: 402px;
  width: 100%;

  max-height: 64px;
  border-radius: 12px;
  border: none;
  padding: 16px;
}

.copy {
  justify-content: space-between;
  span {
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    z-index: 10;
    cursor: text !important;
  }
}

.submit {
  justify-content: center;
  font-size: 22px;
}

.green {
  background-color: #bbf429;
  color: white;
}

.black {
  background-color: black;
  color: white;
}

.white {
  background-color: white;
}
