header {
  align-items: flex-end;
  background-color: #bbf429;
  border-radius: 0 0 24px 24px;
  display: flex;
  justify-content: space-between;
  max-width: 392px;
  padding: 24px;

  .textWrapper {
    max-width: 155px;
    width: 100%;

    &:first-child {
      text-align: left;
    }
    &:last-child {
      text-align: right;
    }
    p {
      margin: 0 0;
      letter-spacing: 1%;
    }

    .title {
      color: #709610;
      font-size: 12px;
      padding-bottom: 4px;
    }

    .info {
      color: #232323;
      font-family: Golos-Bold;
      font-size: 18px;
    }
  }
}
