.wrapper {
  display: flex;
  flex-grow: 1;
  max-width: 430px;
  width: 100vw;
  margin-top: 10px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background-color: white;

  .qrWrapper {
    max-width: 382px;

    .qr {
      width: 40vw;
      max-width: 222px;
    }

    .label {
      width: 109px;
      height: 19px;
      border-radius: 12px 12px 0 0;
      padding: 10px;
      background-color: black;

      span {
        color: white;
        font-size: 16px;
        font-family: Golos-Bold;
      }
    }
  }

  .instructionWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .blockOr {
      display: flex;
      color: #a7a7a7;
      align-items: center;
      gap: 10px;
      max-width: 382px;
      width: 100%;

      .line {
        width: 146px;
        height: 0px;
        border: solid 1px;
      }
    }
  }
}

@media screen and (max-height: 668px) {
  .wrapper {
    gap: 10px;
    .instructionWrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}
@media (min-height: 844px) {
  .wrapper {
    .qrWrapper {
      .qr {
        width: 56vw;
      }
    }
  }
}

@media (min-height: 896px) {
  .wrapper {
    .qrWrapper {
      .qr {
        width: 65vw;
      }
    }
  }
}
@media (min-height: 915px) {
  .wrapper {
    .qrWrapper {
      .qr {
        width: 71vw;
      }
    }
  }
}
